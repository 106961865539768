import React, { ReactElement } from 'react'
import { CosmicObject } from '../services/Api'
import { Link } from 'gatsby'
import styled from 'styled-components'

interface Props {
  article?: CosmicObject;
  isMainArticle?: boolean;
  showContent: boolean;
  showImage: boolean;  
}

interface IContentProps {
  show: boolean;
}

const ArticleContentContainer = styled.p<IContentProps>`
  flex: 1;
  margin-top: 5px;
  display: ${props => props.show === true ? 'block' : 'none'};
`;

const ArticleInfoContainer = styled.footer`
  display: flex;
  font-size: 0.85em;
  justify-content: space-between;
  margin-top: 10px;
`;

const ArticleImageContainer = styled.figure<IContentProps>`
   background: #eee;
   display: block;
   //padding-top: 75%;
   position: relative;
   width: 100%;
   height: 100%;

   display: ${props => props.show === true ? 'block' : 'none'};

  & img {
    display: block;
    height: 100%;
    /* left: 0;
    position: absolute;
    top: 0; */
    width: 100%;
  }

  /* &.is-3by2 {
    padding-top: 66.6666%;
  }

  &.is-16by9 {
    padding-top: 56.25%;
  } */
`;

interface IBodyProps {
  isMainArticle: boolean;
}

const ArticleBodyContainer = styled.div<IBodyProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${props => props.isMainArticle ? '100%' : '80%'};

  .article-title {
    color: #333;
    flex-shrink: 0;
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1.2;    
  }

  @media screen and (min-width: 992px) { 
    .article-title {
      font-size: ${props => props.isMainArticle ? '1.4em' : '1.1em'};
    }
  }
`;

export default function Article(props: Props): ReactElement {  
  return (
    <> {
        props.article !== undefined ?
        (<Link className={"article"} to={`/${props.article.metadata.sub_category}/${props.article.slug}`}>
          {props.showImage ? <ArticleImageContainer className="is-3by2" show={props.showImage}>
            <img src={props.article.metadata.thumbnail.imgix_url + '?q=&h=470'} alt="" />
          </ArticleImageContainer> : <></>}
          <ArticleBodyContainer isMainArticle={props.isMainArticle}>
            <h2 className="article-title">
              {props.article.title}
            </h2>
            <ArticleContentContainer show={props.showContent}>
              {props.article.metadata.short_version}
            </ArticleContentContainer>
            <ArticleInfoContainer>
              {/* <span>By {articleContext.articles[props.articleIndex].metadata.author}</span> */}
              {/* <span>{articleContext.articles[props.articleIndex].comments} comments</span> */}
            </ArticleInfoContainer>
          </ArticleBodyContainer>
        </Link>)
        : <></>
    }
    </>
  )
}
