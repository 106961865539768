import React, { ReactElement } from 'react'
import { CosmicObject } from '../services/Api'
import { Link } from 'gatsby'
import styled from 'styled-components'

interface Props {
  article?: CosmicObject;
  isMainArticle?: boolean;
  showContent: boolean;
  showImage: boolean;
}

interface IContentProps {
  show: boolean;
}

const ArticleImageContainer = styled.figure<IContentProps>`
    display: block;
    position: relative;  
    width: 45%;
    margin-right: 0.5em;

    @media only screen and (min-width: 600px) { 
      width: 100%;   
    }  

    display: ${props => props.show === true ? 'block' : 'none'};

    & img {
      display: block;
      height: 100%;     
      width: 100%;
    }  
`;

interface IBodyProps {
  articleIndex: number;
}

const ArticleBodyContainer = styled.div<IBodyProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${props => props.articleIndex === 0 ? '100%' : '80%'};
  
  .article-title {
    color: #333;
    flex-shrink: 0;
    font-size: ${props => props.articleIndex === 0 ? '1.4em' : '1.1em'};
    font-weight: 700;
    line-height: 1.2;
  }
`;

export default function ShortArticle(props: Props): ReactElement {
  return (
    <> {
      props.article !== undefined ?
        <Link className={"article responsive-article"} to={`/${props.article.metadata.sub_category}/${props.article.slug}`}>
          <ArticleImageContainer show={props.showImage}>
            <img src={props.article.metadata.thumbnail.imgix_url + '?q=&w=600&ar=2:1&fit=crop&crop=faces,center'} alt="" />
          </ArticleImageContainer>
          <ArticleBodyContainer articleIndex={1}>
            <h2 className="article-title">
              {props.article.title}
            </h2>
          </ArticleBodyContainer>
        </Link>
        : <></>
    }
    </>
  )
}
