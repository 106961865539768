import React, { ReactElement } from 'react'
import styled from 'styled-components';
import QDistinctSubCategories from '../queries/QDistinctSubCategories';
import ArticleList from './ArticleList';

const CategoryContainer = styled.div`
	padding-top: 0.5em;	  	
`;

const ArticleListContainer = styled.div`	
  	display: flex;
	flex-direction: column;	

	@media screen and (min-width: 600px) {
		flex-direction: row;
	}
`;

export default function SubCategoryArticleLists(): ReactElement {
	return (
		<QDistinctSubCategories>{
			(distinctCategories) => {
				return distinctCategories.map(subcategory => {
					return <CategoryContainer>
						{subcategory}
						<ArticleListContainer>
							<ArticleList category={subcategory} articleCount={3} showContent={false} showImage={true} />
						</ArticleListContainer>
					</CategoryContainer>
				})
			}
		}</QDistinctSubCategories>
	);
}
