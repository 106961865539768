import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components';
import { ArticlesContext } from '../pages/index'
import Article from './Article'
import { ArticleGridCell } from './ArticleGridCell';

interface MainArticleListProps {
    articleCount: number;
    startFrom: number;
    showContent: boolean;
    showImage: boolean;
}

const ArticleCell = styled(ArticleGridCell)`
    grid-area: more-stories;    
`;

export default function MainArticleList(props: MainArticleListProps): ReactElement {
    const articleContext = useContext(ArticlesContext);

    return (
        <ArticleCell>
            {articleContext && articleContext?.articles?.length ?
                articleContext?.articles?.length >= 0 ? articleContext.articles?.slice(props.startFrom, props.startFrom + props.articleCount).map((article, index) => {
                    return (article !== undefined) ? <>
                        <Article article={article} showContent={props.showContent} showImage={props.showImage}></Article>
                    </> : <></>
                }) : "Loading..."
                : "No articles"
            }
        </ArticleCell>
    )
}
