import styled from "styled-components";

export const ArticleGridCell = styled.div`        
  //grid-row-end: 1;
  /* grid-column-start: 1;
  grid-column-end: span 2;

  @media only screen and (min-width: 600px) {
	grid-column-end: span 1;
  }

  @media only screen and (min-width: 992px) {
	grid-column-end: span 1;
  } */
`;