
import React, { ReactElement, useContext } from 'react'
import styled from 'styled-components';
import { ArticlesContext } from '../pages/index'
import QMostCommentedArticles from '../queries/QMostCommentedArticles';
import { CosmicObject } from '../services/Api';
import ShortArticle from './ShortArticle';

interface MainArticleListProps {
    articleCount: number;    
    showContent: boolean;
    showImage: boolean;
}

const QMostCommentedArticlesStyled = styled(QMostCommentedArticles)`
    grid-area: most-commented-stories;
`;

export default function MostCommentedArticlesList(props: MainArticleListProps): ReactElement {
    const articleContext = useContext(ArticlesContext);

    return (
        <QMostCommentedArticlesStyled>{ (mostCommentedArticles) =>
            {return articleContext && articleContext?.articles?.length ?
                articleContext?.articles?.length >= 0 ? (mostCommentedArticles as CosmicObject[]).slice(0, 4).map((article, index) => {
                    return (article !== undefined) ? <>
                        <ShortArticle article={articleContext.articles.filter(a => a.slug === article.context.slug)[0]} showContent={props.showContent} showImage={props.showImage}></ShortArticle>                        
                    </> : <></>
                }) : "Loading..."
                : "No articles"
            }
        }</QMostCommentedArticlesStyled>
    )
}
