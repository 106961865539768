import React, { useEffect, useState, createContext } from 'react';
import Article from '../components/Article'
import MainArticleList from '../components/MainArticleList';
import styled from 'styled-components';
import { CosmicObject } from '../services/Api';
import { graphql } from 'gatsby';
import ShortArticleList from '../components/ShortArticleList';
import Layout from '../components/Layout';
import SubCategoryArticleLists from '../components/SubCategoryArticleLists';
import { ArticleGridCell } from '../components/ArticleGridCell';
import MostCommentedArticlesList from '../components/MostCommentedArticlesList';

export interface IServerSideStateContext {
  state: CosmicObject[] | null
};

export const ServerSideStateContext = createContext<IServerSideStateContext>({ state: null });

export interface IArticlesContext {
  articles: CosmicObject[] | null,
  // onClickLikeButton?: (slug: string) => void,
  // onClickAddCommentButton?: (slug: string, commentText: string) => void
};

export const ArticlesContext = createContext<IArticlesContext>({} as IArticlesContext);

const TopLeftCornerLargeContainer = styled(ArticleGridCell)`
  grid-area: main-story;  
`;

const LeftMiddleMediumContainer = styled(ArticleGridCell)`
  grid-area: secondary-story;  
`;

const MainGridContainer = styled.div`
  display: grid;  
  grid-row-gap: 1em;
  grid-column-gap: 10px;
  
  grid-template-areas:
    "header-main"
    "main-story"
    "secondary-story"
    "more-stories-header"
    "more-stories"
    "most-commented-header"
    "most-commented-stories"
    ;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, minmax(140px, 1fr));
    grid-template-rows: 1em repeat(4, 6em);
    
    grid-template-areas:
      "header-main              more-stories-header"
      "main-story               more-stories"
      "main-story               more-stories"
      "main-story               more-stories"
      "secondary-story          more-stories"      
      "most-commented-header    most-commented-header"
      "most-commented-stories   most-commented-stories"
    ;
  }

  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(4, minmax(140px, 1fr));
    grid-template-rows: 1em repeat(4, 10em);
    
    grid-template-areas:
      "header-main              header-main              more-stories-header    most-commented-header"
      "main-story               main-story               more-stories           most-commented-stories"
      "main-story               main-story               more-stories           most-commented-stories"
      "main-story               main-story               more-stories           most-commented-stories"
      "secondary-story          secondary-story          more-stories           most-commented-stories"      
    ;
  }  
`;

const TopLeftCornerHeader = styled.div`
  grid-area: header-main;
`;

const CenterHeader = styled.div`
  grid-area: more-stories-header;  
`;

const MostCommentedHeader = styled.div`
  grid-area: most-commented-header;  
`;

export default function Home({ data }) {
  const [articles, setArticles] = useState<null | CosmicObject[]>(null);

  useEffect(() => {
    if (data) {
      const articles = data.allCosmicjsArticles.edges.map(e => {
        return e.node;
      });
      setArticles(articles);
    }

    try {
      const realCall = async () => {
        await fetch(`/api/TestAuth`, {
          method: 'get'          
        });
      };

      realCall();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <> { articles ?
      <ArticlesContext.Provider value={{ articles: articles }}>
        <Layout mainPage={true}>
          <MainGridContainer>
            <TopLeftCornerHeader>Latest articles</TopLeftCornerHeader>
            <TopLeftCornerLargeContainer>
              <Article article={articles[0]} showContent={true} showImage={true} isMainArticle={true}></Article>
            </TopLeftCornerLargeContainer>
            <LeftMiddleMediumContainer>
              <Article article={articles[1]} showContent={true} showImage={false}></Article>
            </LeftMiddleMediumContainer>
            <CenterHeader>More articles</CenterHeader>
            <MainArticleList articleCount={4} startFrom={2} showContent={true} showImage={false} />
            <MostCommentedHeader>Most commented</MostCommentedHeader>
            <MostCommentedArticlesList articleCount={4} showContent={false} showImage={true} />
          </MainGridContainer>
          <SubCategoryArticleLists />
        </Layout>
      </ArticlesContext.Provider> : <></>}
    </>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
          allCosmicjsArticles(sort: {fields: [created], order: DESC }, limit: 1000) {
          edges {
          node {
          metadata {
            is_production,
            thumbnail {
              imgix_url
            },
            short_version,
            sub_category
          }
          slug
          title
          content
          created(formatString: "DD MMMM, YYYY")
        }
      }
    }    
  }
`