import { graphql, StaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

export default function QMostCommentedArticles({ children }): ReactElement {
	return <StaticQuery
		query={graphql`
			query MostCommentedArticles {
				allSitePage(sort: {order: ASC, fields: fields___commentCount}, filter: {fields: {commentCount: {ne: null}}}) {
					nodes {
						fields {
							commentCount
						}
						context {
							slug
						}
					}
				}
			}
		  `}
		render={data => {
			return children(data && data.allSitePage.nodes)
		}}
	/>
}
