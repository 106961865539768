
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { CosmicObject } from '../services/Api';
import { ArticlesContext } from '../pages/index'
import ShortArticle from './ShortArticle';

interface MainArticleListProps {
    articleCount: number;    
    showContent: boolean;
    showImage: boolean;
    category: string;
}

const shortArticleStyle = {    
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "25%",
    margin: "10px"
};

export default function ArticleList(props: MainArticleListProps): ReactElement {
    const articleContext = useContext(ArticlesContext);
    const [myArticles, setMyArticles] = useState<CosmicObject[]>([]);

    useEffect(() => {
        const realCall = () => {
            const filterFn = (a: CosmicObject) => a.metadata.sub_category === props.category;
            let c = articleContext.articles?.filter(filterFn).slice(0, props.articleCount);
            
            setMyArticles(c as []);
        }

        realCall();
    }, [articleContext.articles]);

    return (
        <>
            {myArticles && myArticles.length ?
                myArticles.length >= 0 ? myArticles.map((article, index) => {
                    return (article !== undefined) ? <div style={shortArticleStyle}>
                        <ShortArticle article={article} showContent={props.showContent} showImage={props.showImage}></ShortArticle>
                    </div> : <></>
                }) : "Loading..."
                : "No articles"
            }
        </>
    )
}
